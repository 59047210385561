import React from 'react';
import Context from '../../assets/js/Context';
import Cookies from "universal-cookie";
import host from '../../assets/js/Host';
import axios from 'axios';
import { Row, Col } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Component from "@reactions/component";
import { SideSheet, Position, Pane, Dialog, Paragraph, Card, Heading } from 'evergreen-ui';
import { ToastContainer, toast } from 'react-toastify';
import Info from '@material-ui/icons/Info';
import ReplayIcon from '@material-ui/icons/Replay';
import FileUpload from '@material-ui/icons/SyncRounded';

import SyncDisabledRounded from '@material-ui/icons/SyncDisabledRounded';

import CategoryIcon from '@material-ui/icons/Category';
import Error from '@material-ui/icons/ReportProblem';
// import Lottie from 'lottie-react-web';
// import animation from '../../assets/json/clean.json';
import 'react-toastify/dist/ReactToastify.css';
import MaterialDatatable from "material-datatable";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import qs from 'qs';

import * as moment from "moment-timezone";
import Login from '../common/login';
import exportFromJSON from 'export-from-json'
import { Link } from 'react-router-dom';

const columns = [
    { field: "print", name: " اعادة طباعة", options: { width: 200, filter: false, sort: false, } },
    { field: "info", name: "قائمة طباعة", options: { width: 200, filter: false, sort: false, } },
    { field: "items", name: "المواد", options: { width: 200, filter: false, sort: false, } },
    { field: "send", name: " الارسال", options: { width: 200, filter: false, sort: false, } },
    { field: "createdAt", name: "التاريخ", options: { filter: false, sort: true, } },
    { field: "name", name: "اسم الناقل", options: { filter: true, sort: true, } },
    { field: "count", name: "عدد الطلبات", options: { filter: false, sort: true, } },
    { field: "id", name: "رقم القائمة", options: { width: 200, filter: true, sort: false, } },

];
const options = {
    selectableRows: false,
    responsive: 'scroll',
    rowCursorHand: false,
    print: true,
    sort: true,
    rowsPerPage: 300,

    filter: false,

    download: false,
    onSearchChange: (e) => {
        console.log(e);
    },
    textLabels: {
        body: {
            noMatch: " لم يتم العثور على سجلات مطابقة",
            toolTip: "فرز",
        },
        pagination: {
            next: "الصفحة التالية",
            previous: "الصفحة السابقة",
            rowsPerPage: "عدد الصفوف",
            displayRows: "من",
        },
        toolbar: {
            search: "بحث",
            downloadCsv: "تنزيل",
            print: "Print",
            viewColumns: " التحكم بالاعمدة",
            filterTable: "فلتر",
        },
        filter: {
            all: "الكل",
            title: "فلتر",
            reset: "إعادة تعيين",
        },
        viewColumns: {
            title: "عرض الأعمدة",
            titleAria: "إظهار / إخفاء أعمدة الجدول",
        },

    }
}
const cookies = new Cookies();

class Selles_Reports extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            orders: [],
            from: new Date(),
            to: new Date(),
            spin: false,
            serch: false,
            total: '',
            total_items: 0,
            total_orders: 0,
            items_cost: 0,
            orders_cost: 0,
            ordes2: []

        }

    }

    getMuiTheme = () => createMuiTheme({
        overrides: {
            MaterialDatatableBodyCell: {
                root: {
                    //   backgroundColor: "#FF0000",
                    textAlign: 'right',
                },

            },
            MuiTableCell: {
                root: {
                    textAlign: 'right',

                },
                head: {
                    // backgroundColor: "#FF0000",
                }
            },

        }
    })

    componentDidMount() {

        var header = { "Content-Type": "application/json", token: cookies.get("token") };

        axios.get(host + `users/printed/orders/all`, { headers: header })
            .then(res => {
                let arr = [];

                let items = res.data.data.printed

                for (let i = 0; i < items.length; i++) {
                    let obj = {
                        i: i,
                        name: <p> <Error style={items[i].hasChange == true ? { color: '#ffc107', cursor: 'pointer', } : { display: 'none' }} />

                            {items[i].name}</p>,
                        id: items[i].id,
                        count: items[i].count,
                        createdAt: moment(items[i].createdAt).format('DD/MM/YYYY'),
                        items: <Link to={`/Dayle_items?id=${items[i].id}`}><CategoryIcon style={{ color: '#ffc107' }} /></Link>,
                        send: "",
                        info: <Link to={`/dayle_orders?id=${items[i].id}`}><Info /></Link>,
                        print: <a href={`https://pages.enfirad.com/Print2/${items[i].id}`}><ReplayIcon style={{ color: 'green' }} /></a>
                    };
                    if (items[i].is_send == true) {
                        obj.send = <FileUpload style={{ color: '#28a745', cursor: 'pointer' }} />
                    } else {
                        obj.send =
                            <Component initialState={{ isShown: false }}>
                                {({ state, setState }) => (
                                    <Pane>
                                        <Dialog
                                            isShown={state.isShown}
                                            title="ارسال القائمة"
                                            intent="success"
                                            onCloseComplete={() => setState({ isShown: false })}
                                            confirmLabel="ارسال"
                                            cancelLabel="الغاء"
                                            onConfirm={() => {
                                                setState({ isShown: false })
                                                this.update_list(items[i].id)

                                                this.componentDidMount()
                                            }}
                                        >
                                            <span id='msgDelete'> هل انت متأكد من ارسال القائمة رقم {items[i].id}</span>
                                        </Dialog>
                                        <SyncDisabledRounded id="sendBtn" style={{ color: '#043037', cursor: 'pointer' }} onClick={() => { setState({ isShown: true }) }} />

                                    </Pane>
                                )}
                            </Component>
                    }

                    arr.push(obj);
                }


                this.setState({
                    orders: arr, spin: false,
                    total_items: res.data.data.items_sum,
                    total_orders: res.data.data.order_count,
                    items_cost: res.data.data.items_cost,
                    orders_cost: res.data.data.sells,
                });
            })
            .catch(error => {
                console.log(error.response)
            });
    }
    handleChangeFrom = date => {
        console.log(date);

        this.setState({
            from: date
        });
    };
    handleChangeTo = date => {
        this.setState({
            to: date
        });
    };


    update_list(id) {
        toast("انتظر قليلآ لحين الانتهاء من ارسال القائمة", {
            position: "top-center",
            autoClose: 20000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
        });

        document.getElementById('sendBtn').style.display = 'none'
        var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token") };
        axios.post(host + `dashbord/list/send/${id}`, { headers: header })
            .then(response => {

                toast("تم ارسال القائمة بنجاح", {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
                this.componentDidMount()
            })
            .catch(error => { console.log(error) })
    }
    render() {
        if (cookies.get("token")) {
            return (
                <Context.Consumer>
                    {ctx => {
                        // if (this.state.spin) {
                        return (
                            <div >

                                <div id='navDashContiner'>

                                </div>


                                <br />

                                <div>
                                    <div className='DataTableContiner'>
                                        <MuiThemeProvider
                                            theme={this.getMuiTheme()}>
                                            <MaterialDatatable data={this.state.orders} columns={columns} options={options} />
                                        </MuiThemeProvider>
                                    </div>

                                </div>

                                <ToastContainer
                                    position="bottom-left"
                                    autoClose={10000}
                                    hideProgressBar={false}
                                    newestOnTop={false}
                                    closeOnClick
                                    rtl={false}
                                    pauseOnVisibilityChange
                                    draggable
                                    pauseOnHover
                                />
                            </div>
                        )
                        // }
                        // else {
                        //     return (
                        //         <div style={{ width: '100%', height: 'calc(100vh - 60px)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        //             <Lottie
                        //                 options={{
                        //                     animationData: animation
                        //                 }}
                        //             />
                        //         </div>
                        //     )
                        // }
                    }
                    }
                </Context.Consumer>
            )
        }
        else {
            return (
                <Login />
            )
        }
    }
}


export default Selles_Reports;