import React from "react";
import Context from '../../assets/js/Context';
import { Form, Row, Col, Button, Container, Card, Image } from 'react-bootstrap';
import axios from "axios";
import MultiSelect from "@kenshooui/react-multi-select";
import "@kenshooui/react-multi-select/dist/style.css"
import Cookies from "universal-cookie";
import { FaArrowCircleLeft, } from "react-icons/fa";

import { Link } from 'react-router-dom';
import host from "../../assets/js/Host";


const cookies = new Cookies();

class SubmitForm extends React.Component {
    constructor() {
        super();
        this.displayDataAdt = [];
        this.displayDataAdt2 = [];
        this.state = {
            items: [],
            pages: [],
            orders: [],
            file: [],
            file1: [],
            file2: [],
            file3: [],
            file4: [],
            file5: [],
            selectedItems: []
        };

    }
    componentDidMount() {
        var header = { "Content-Type": "application/json", Authorization: cookies.get("token") };

        axios.get(host + `users/all/items/`, { headers: header })
            .then((response) => {
                //this.setState({ items: response.data.data })
                this.setState({ items: response.data.data.data })
            })
        axios.get(host + `users/pages/name/all`, { headers: header })
            .then((response) => {
                //this.setState({ items: response.data.data })
                this.setState({ pages: response.data.data.pages })
                console.log(response.data.data.pages);
            })

    }

    onChangeD(value, item, text) {

        let count = document.getElementById(value).value;
        if (count > 1) {
            let newValue = Number(document.getElementById(value).value) - 1;
            document.getElementById(value).value = newValue
            let re = ((item.count - count + 1) + 1)
            console.log(re);
            document.getElementById(text).textContent = `الكمية المتبقية ${re}`

        }

    }
    onChangeI(value, item, text) {
        let count = document.getElementById(value).value;
        if (count >= 1) {
            if (count <= (item.count - 1)) {


                let newValue = Number(document.getElementById(value).value) + 1
                document.getElementById(value).value = newValue;
                document.getElementById(text).textContent = `الكمية المتبقية ${(item.count - 1) - (Number(document.getElementById(value).value) - 1)}`
            }
        }

    }
    submit() {

        let rep = document.getElementById('rep').checked
        let dep = document.getElementById('dep').checked





        let name = document.getElementById('name').value;
        let pagesName = document.getElementById('pagesName').value;
        let account = document.getElementById('account').value;
        let phone = document.getElementById('phone').value;
        let city = document.getElementById('city').value;
        let adrees = document.getElementById('adrees').value;
        let price = document.getElementById('price').value;
        let notes = document.getElementById('notes').value;
        let selectedItems = this.state.selectedItems;
        let items = []
        let notes2 = ``;

        if (selectedItems.length === 0) {
            this.setState({ spin: false })
            document.getElementById('itemsCenter').style.display = 'block'
            return -1
        } else {
            document.getElementById('itemsCenter').style.display = 'none'

        }

        for (let index = 0; index < selectedItems.length; index++) {
            let obj = {
                id: selectedItems[index].id,
                name: selectedItems[index].label,
                count: Number(document.getElementById(selectedItems[index].label).value),
            }

            if (notes2.length === 0) {

                notes2 = `(${obj.count} * ${obj.name})`

            } else {
                notes2 = `${notes2} / (${obj.count} * ${obj.name})`

            }
            items.push(obj)
        }


        // if (!account) {
        //     document.getElementById('account').style.border = '1px solid #043037';
        //     document.getElementById('accounttext').textContent = 'عليك ادخال رابط الحساب';
        //     document.getElementById('accounttext').style.color = '#043037';
        //     document.getElementById('accounttext').style.display = 'flex'

        //     return -1;

        // }
        // else
        if (!phone || phone.length !== 11) {
            document.getElementById('accounttext').style.display = 'none'
            document.getElementById('account').style.border = '1px solid #ccc';
            document.getElementById('phone').style.border = '1px solid #043037';
            document.getElementById('phonetext').textContent = 'رقم الهاتف غير صحيح';
            document.getElementById('phonetext').style.color = '#043037';
            document.getElementById('phonetext').style.display = 'flex';
            return -1;
        }
        else if (!city || city === '1') {
            document.getElementById('phonetext').style.display = 'none'
            document.getElementById('phone').style.border = '1px solid #ccc';
            document.getElementById('city').style.border = '1px solid #043037';
            document.getElementById('citytext').textContent = 'قم باختيار محافظة';
            document.getElementById('citytext').style.color = '#043037';
            document.getElementById('citytext').style.display = 'flex'
            return -1;
        }
        if (!pagesName || pagesName === "non") {
            document.getElementById('pagetext').style.display = 'none'
            document.getElementById('pagesName').style.border = '1px solid #ccc';
            document.getElementById('pagesName').style.border = '1px solid #043037';
            document.getElementById('pagetext').textContent = 'ادخل اسم الصفحة اللتي تم الحجز منها';
            document.getElementById('pagetext').style.color = '#043037';
            document.getElementById('pagetext').style.display = 'flex'
            return -1;
        }
        else if (!price) {
            document.getElementById('pricetext').style.display = 'none'
            document.getElementById('city').style.border = '1px solid #ccc';
            document.getElementById('price').style.border = '1px solid #043037';
            document.getElementById('pricetext').textContent = 'ادخل سعر مع التوصيل';
            document.getElementById('pricetext').style.color = '#043037';
            document.getElementById('pricetext').style.display = 'flex'
            return -1;
        }
        else {
            document.getElementById('pricetext').style.display = 'none'
            document.getElementById('price').style.border = '1px solid #ccc';
            document.getElementById('BTN').style.display = 'none'
            document.getElementById('BTNW').style.display = 'flex'
            let bodyFormData = new FormData();
            bodyFormData.append('name', name,);
            bodyFormData.append('account', account,);
            bodyFormData.append('phone', phone,);
            bodyFormData.append('government', city);
            bodyFormData.append('city', adrees);
            bodyFormData.append('price', price);
            bodyFormData.append('notes', notes);
            bodyFormData.append('notes2', notes2);
            bodyFormData.append('page_name', pagesName);
            if (this.state.file1.length > 0) {
                bodyFormData.append('file', this.state.file1[0]);
                console.log('this.state.file1.length');
            }
            if (this.state.file2.length > 0) {
                bodyFormData.append('file', this.state.file2[0]);
                console.log('this.state.file2.length');
            }
            if (this.state.file3.length > 0) {
                bodyFormData.append('file', this.state.file3[0]);
                console.log('this.state.file3.length');
            }
            if (this.state.file4.length > 0) {
                bodyFormData.append('file', this.state.file4[0]);
                console.log('this.state.file5.length');
            }
            if (this.state.file5.length > 0) {
                bodyFormData.append('file', this.state.file5[0]);
                console.log('this.state.file5.length');
            }
            bodyFormData.append('items', JSON.stringify(items));
            bodyFormData.append('dep', dep);
            bodyFormData.append('rep', rep);
            axios({
                method: 'post',
                url: host + 'users/user/from/order/add',
                headers: { 'Authorization': cookies.get("token") },
                data: bodyFormData
            }).then((response) => {
               window.location.href = '/Done'
            }).catch((error) => {
                document.getElementById('BTN').style.display = 'flex'
                document.getElementById('BTNW').style.display = 'none'
               window.location.href = '/Error'
            })
        }
    }

    render() {
        const { items, selectedItems } = this.state;
        return (
            <div>
                <Context.Consumer>
                    {ctx => {
                        return (
                            <div>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', height: 100, padding: 20 }}>

                                    <Link to='/'>
                                        <FaArrowCircleLeft style={{ color: '#000', cursor: 'pointer', fontSize: 30 }} />
                                    </Link>
                                    <h4>انشاء طلب</h4>
                                </div>
                                <div>

                                    <Container fluid>

                                        <Row>
                                            <Col md={{ span: 4, offset: 4 }}>
                                                <div>
                                                    <Form.Group className="mb-3" dir="rtl">
                                                        <Form.Label >الاسم</Form.Label>
                                                        <Form.Control type="text" id="name" placeholder="الاسم" />
                                                        <Form.Text className="text-muted">
                                                            اسم الزبون
                                                        </Form.Text>
                                                    </Form.Group>

                                                    <Form.Group className="mb-3" dir="rtl">
                                                        <Form.Label >رابط حساب الزبون </Form.Label>
                                                        <Form.Control type="text" id="account" placeholder="رابط حساب الزبون" />
                                                        <small style={{ display: 'none' }} id="accounttext"  >
                                                            رابط الزبون على مواقع التواصل الاجمتماعي
                                                        </small>
                                                    </Form.Group>
                                                    <Form.Group className="mb-3" dir="rtl">
                                                        <Form.Label >رقم الزبون</Form.Label>
                                                        <Form.Control type="number" id="phone" placeholder="رقم الزبون"
                                                            />
                                                        <small style={{ display: 'none' }} id="phonetext"  >
                                                            رقم هاتف الزبون
                                                        </small>
                                                    </Form.Group>
                                                    <div id="ordersHisotry" style={{ textAlign: 'center', border: 'solid 1px', display: 'none' }} >

                                                    


                                                    </div>
                                                    {/* <Form.Group className="mb-3" dir="rtl">
                                                        <Form.Label >المحافظة</Form.Label>
                                                        <Form.Select aria-label="اختر المحافظة" id="city" dir="rtl">
                                                            <option value="1">اخر محافظة</option>
                                                            <option value="بغداد">بغداد</option>
                                                            <option value="بابل">بابل</option>
                                                            <option value="الأنبار">الأنبار</option>
                                                            <option value="البصرة">البصرة</option>
                                                            <option value="دهوك">دهوك</option>
                                                            <option value="القادسية">القادسية</option>
                                                            <option value="ديالى">ديالى</option>
                                                            <option value="ذي قار">ذي قار</option>
                                                            <option value="السليمانية">السليمانية</option>
                                                            <option value="صلاح الدين">صلاح الدين</option>
                                                            <option value="كركوك">كركوك</option>
                                                            <option value="كربلاء">كربلاء</option>
                                                            <option value="المثنى">المثنى</option>
                                                            <option value="النجف">النجف</option>
                                                            <option value="نينوى">نينوى</option>
                                                            <option value="واسط">واسط</option>
                                                            <option value="ميسان">ميسان</option>
                                                            <option value="اربيل">اربيل</option>

                                                        </Form.Select>
                                                        <small id="citytext" style={{ display: 'none' }}>
                                                            اختر محافظة
                                                        </small>
                                                    </Form.Group> */}

                                                    <Form.Group className="mb-3" dir="rtl">
                                                        <Form.Label >المنطقة</Form.Label>
                                                        <Form.Control type="text" id="adrees" placeholder="المنطقة" />
                                                    </Form.Group>

                                                    {/* <Form.Group className="mb-3" dir="rtl" >
                                                        <Form.Label>الصفحة</Form.Label>
                                                        <Form.Select id="pagesName">
                                                            <option value={'non'}>اختر صفحة</option>
                                                            {this.state.pages.map((item, i) => (
                                                                <option value={item.id}>{item.name}</option>
                                                            ))}
                                                        </Form.Select>
                                                        <Form.Text className="text-muted">
                                                            صفحة اللتي تم الحجز منا
                                                        </Form.Text>
                                                        <small style={{ display: 'none' }} id="pagetext"  >
                                                            الرجاء اختيار اسم الصفحة
                                                        </small>
                                                    </Form.Group> */}


                                                    <div>
                                                        <center>    <h2 style={{ margin: "20px" }}> اختر منتج</h2></center>
                                                        <MultiSelect
                                                            items={items}
                                                            selectedItems={selectedItems}
                                                            height={'50px'}

                                                            onChange={(e) => {
                                                                this.setState({ selectedItems: e })
                                                            }}

                                                            showSelectedItems={false}
                                                            showSelectAll={false}
                                                        />
                                                    </div>

                                           
                                                    <br></br>
                                                    <Form.Group className="mb-3" dir="rtl">
                                                        <Form.Label >السعر  مع التوصيل</Form.Label>
                                                        <Form.Control type="number" id="price" placeholder="السعر مع التوصيل" />
                                                        <small id="pricetext" style={{ display: 'none' }}>
                                                            اختر محافظة
                                                        </small>

                                                    </Form.Group>

                                                    <center>    <h4 style={{ margin: "40px" }}>نوع الطلب</h4></center>
                                                    <Form.Group className="mb-3" dir="rtl">
                                                        <Form.Check
                                                            type={'radio'}
                                                            inline
                                                            defaultChecked
                                                            name="group1"
                                                            id={`new`}
                                                            label={`طلب جديد`}
                                                        />
                                                        <Form.Check
                                                            type={'radio'}
                                                            inline

                                                            name="group1"
                                                            id={`dep`}
                                                            label={`طلب مكرر`}

                                                        />
                                                        <Form.Check
                                                            type={'radio'}
                                                            inline

                                                            name="group1"
                                                            label={`استبدال`}
                                                            id={`rep`}
                                                        />


                                                    </Form.Group>

                                                    <Form.Group className="mb-3" dir="rtl">
                                                        <Form.Label >ملاحظات</Form.Label>
                                                        <Form.Control
                                                            as="textarea"
                                                            id="notes"
                                                            placeholder="الملاحظات"
                                                            style={{ height: '100px' }}
                                                        />
                                                    </Form.Group>


                                                    <center id="itemsCenter" style={{ display: 'none' }}>
                                                        <h5 id="itemstext" style={{ color: 'red' }}>
                                                            ! لم يتم اختيار اي منتج
                                                        </h5>
                                                    </center>
                                                    <center>
                                                        <Button variant="primary" id='BTN' onClick={() => {
                                                            this.submit()
                                                        }}>
                                                            ارسال
                                                        </Button>

                                                        <Button variant="primary" style={{ backgroundColor: '#999', display: 'none' }} id='BTNW'>
                                                            الرجاء الانتظار
                                                        </Button>
                                                    </center>
                                                    <br></br>
                                                </div>

                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                            </div>
                        )


                    }}
                </Context.Consumer>
            </div>
        );
    }
}

export default SubmitForm;