import React from 'react';
import Context from '../../assets/js/Context';
import Cookies from "universal-cookie";
import host from '../../assets/js/Host';
import axios from 'axios';
import qs from 'qs';
import Component from "@reactions/component";
import { Spinner, Icon } from 'evergreen-ui';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import Lottie from 'lottie-react-web';
// import animation from '../../assets/json/clean.json';
import { Row, Col, Container, Form, Button, Card } from 'react-bootstrap';
import Login from './login';
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import { MultiSelect } from "react-multi-select-component";
import Multiselect from 'multiselect-react-dropdown';
import { MultiSelectComponent } from '@syncfusion/ej2-react-dropdowns';
import Select from 'react-select'
import MobM from '../../assets/img/mobM.png'
import MB2 from '../../assets/img/mb2.webp'


const options = [
    { label: "Grapes 🍇", value: "grapes", isDisabled: false },
    { label: "Mango 🥭", value: "mango", isDisabled: true },
    { label: "Strawberry 🍓", value: "strawberry", isDisabled: true },
];

const cookies = new Cookies();

const handleOnSearch = (string, results) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    console.log(string, results)
}

const handleOnHover = (result) => {
    // the item hovered
    console.log(result)
}


const formatResult = (item) => {
    return (
        <>
            {/* <span style={{ display: 'block', textAlign: 'left' }}>id: {item.id}</span> */}
            <span style={{ display: 'block', textAlign: 'left' }}> {item.name}</span>
        </>
    )
}

const handleOnFocus = () => {
    console.log('Focused')
}

class Category extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            outItems: [],
            spin: false,
            options: [],
            optionsSelcted: [],
            itemIn: {},
            itemOut: {},
            fields: { value: 'name', disabled: 'disabled' }
        }
    }

    componentDidMount() {
        var header = { "Content-Type": "application/x-www-form-urlencoded", Authorization: cookies.get("tokenUser") };
        axios.get(host + `dashbord/category/${this.props.match.params.id}`, { headers: header })
            .then(res => {
                // console.log(res.data.data.items);

                this.setState({
                    items: res.data.data.items, spin: true
                });
            })
            .catch(error => { console.log(error.response) })




        axios.get(host + `users/get/items/sana/all?cat_id=2`)
            .then(res => {
                this.setState({ items: res.data.items })

            })
            .catch(error => {
                console.log(error.response)
            });

        axios.get(host + `users/get/items/sana/all`)
            .then(res => {
                this.setState({ outItems: res.data.items })

            })
            .catch(error => {
                console.log(error.response)
            });




    }

    onSelect(selectedList) {

        axios.get(host + `users/parts/${selectedList.id}`)
            .then(res => {
                console.log(res.data.parts);

                this.setState({ options: res.data.parts, itemIn: selectedList })

            })
            .catch(error => {
                console.log(error.response)
            });




    }

    onRemove(item) {
        this.setState({ options: [], itemIn: {} })

    }



    onSelectFin(selectedList) {
        this.setState({ itemOut: selectedList })




    }

    onRemoveFin(item) {
        this.setState({ itemOut: {} })

    }

    addNew() {
        let _inItem = this.state.itemIn;
        let _ouItem = this.state.itemOut;
        let op = this.state.optionsSelcted;

        var radios = document.getElementsByName('group1');
        let check = ""
        for (var i = 0, length = radios.length; i < length; i++) {
            if (radios[i].checked) {
                // do whatever you want with the checked radio

                check = radios[i].value
                // only one radio can be logically checked, don't check the rest
                break;
            }
        }




        var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token") };
        let formData = new FormData();
        formData.append("inItem", _inItem.id);
        formData.append("ouItem", _ouItem.id);
        formData.append("check", check);
        for (let index = 0; index < op.length; index++) {
            formData.append("op_ids", op[index].id);

        }
        axios({ url: host + `users/sana/orders/new`, method: "post", data: formData, headers: header })
            .then(response => {

                window.alert(`رقم الجهاز هو ${response.data.id}`)
            })
            .catch(error => { console.log(error.response.data.message) })



    }
    render() {
        if (cookies.get("tokenUser")) {
            return (
                <Context.Consumer>
                    {ctx => {
                        // if (this.state.spin) {
                        return (
                            <div id='HomeContiner'>
                                <div id='navDashContiner'>
                                    <Link to='/Category1'>
                                        <Icon icon="circle-arrow-left" size={30} color="#fff"
                                            style={{ cursor: 'pointer' }} />
                                    </Link>
                                    <h6>{localStorage.getItem('cateName')}</h6>
                                </div>
                                <div id='HomeContiner1'>
                                    <br></br>
                                    <Container>

                                        <Row className="justify-content-md-center">
                                            <Col md="auto">
                                                <Card style={{ width: '18rem' }}>
                                                    <Card.Img variant="top" src={MobM} />
                                                    <Card.Body>
                                                        <center> <Button variant="primary">تصليح جهاز جديد </Button></center>


                                                    </Card.Body>
                                                </Card>
                                            </Col>


                                        </Row>
                                        <br></br>

                                        <Row className="justify-content-md-center">
                                            <Col md="auto">
                                                <Card style={{ width: '18rem' }}>
                                                    <Card.Img variant="top" src={MB2} />
                                                    <Card.Body>
                                                        <center> <Button variant="primary"> الاجهزة السابقة </Button></center>


                                                    </Card.Body>
                                                </Card>
                                            </Col>


                                        </Row>
                                    </Container>

                                </div>
                                <ToastContainer
                                    position="bottom-left"
                                    autoClose={10000}
                                    hideProgressBar={false}
                                    newestOnTop={false}
                                    closeOnClick
                                    rtl={false}
                                    pauseOnVisibilityChange
                                    draggable
                                    pauseOnHover
                                />
                            </div>
                        )
                        // }
                        // else {
                        //     return (
                        //         <div style={{ width: '100%', height: 'calc(100vh - 60px)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        //             <Lottie
                        //                 options={{
                        //                     animationData: animation
                        //                 }}
                        //             />
                        //         </div>
                        //     )
                        // }
                    }
                    }
                </Context.Consumer>
            )
        }
        else {
            return (
                <Login />
            )
        }
    }
}


export default Category;